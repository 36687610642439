<template>
  <div class="marketing-company">
    <el-row class="marketing-company-filter">
      <el-form label-width="auto" :inline="true" size="mini">
        <el-col :span="7" :offset="1">
          <el-form-item label="公司名称:">
            <el-input placeholder="请输入公司全称" class="order-input-filter" clearable
                      v-model="pagination.name"
                      @change="loadCompany"
                      prefix-icon="el-icon-search"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="地理位置:">
            <el-cascader
              class="device-input-filter"
              size="mini"
              :options="options"
              :props="{ expandTrigger: 'hover' }"
              @change="handleAreaChange">
            </el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="5">
          <el-form-item label="排序方式:">
            <el-select placeholder="请选取排序方式" v-model="pagination.orderType" class="order-input-filter"
                       @change="loadCompany"
                       size="mini">
              <el-option v-for="item in orderTypes"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" size="mini" plain @click="handleFlushCompany">检索客户</el-button>
          <el-button type="primary" size="mini" @click="handleCreateCompanyVisible">新增客户</el-button>
        </el-col>
      </el-form>
    </el-row>
    <el-row class="marketing-company-item" v-for="company in companies" @click.native="handleGoDetail(company)">
      <el-col :span="4" class="marketing-company-item-name">
        <p class="marketing-company-label">本月剩余免费杯量: {{company.usableFreeCups}}</p>
        <el-progress type="circle" :percentage="0" :width="64" v-if="company.freeCups === 0"></el-progress>
        <el-progress type="circle" :percentage="parseInt((company.usableFreeCups * 100 / company.freeCups).toFixed(2))"
                     :width="64"
                     v-else></el-progress>
      </el-col>
      <el-col :span="20" class="marketing-company-item-info">
        <el-row>
          <el-col :span="10" class="marketing-company-label">公司名称：<span class="important">{{company.name}}</span>
          </el-col>
          <el-col :span="9" class="marketing-company-label right">地理位置：<span>
            {{company.province}}{{company.city}}{{company.address}}</span></el-col>
          <el-col :span="4" class="marketing-company-label  warning right" v-show="company.hasOldCombination !== 0">
            存在套餐即将过期
          </el-col>
        </el-row>

        <el-row class="marketing-company-item-content">
          <el-col :span="4">
            <p class="number">{{company.cups}}</p>
            <p>本月杯量</p>
          </el-col>

          <el-col :span="6">
            <p class="number">¥ {{((company.rent + company.revenue) / 100) | F1000}}</p>
            <p>营收账款<span>(元)</span></p>
          </el-col>
          <el-col :span="4">
            <p class="number">{{company.sumCups}}</p>
            <p>累积制作量<span>(杯)</span></p>
          </el-col>

          <el-col :span="4">
            <p class="number">{{company.combinations}}</p>
            <p>套餐数</p>
          </el-col>
          <el-col :span="4">

            <el-switch
              disabled
              class="switch"
              :active-value="1"
              :inactive-value="0"
              v-model="company.employeeLimit"
              active-color="#13ce66"
              inactive-color="#f2f2f2">
            </el-switch>
            <p>员工限定模式</p>
          </el-col>

          <el-col :span="2" class="right">
            <i class="el-icon-arrow-right more "></i>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div class="marketing-company-empty" v-if="companies.length ===0">
      <empty slot="empty" ></empty>
    </div>

    <el-pagination
      @current-change="handleCurrentChange"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>

    <c-dialog :visible.sync="addCompanyVisible" :title="'新增客户'" :width="'40rem'" :height="'36rem'">
      <div class="create-company">
        <el-form label-width="100px" size="small">
          <el-form-item label="客户名称">
            <el-input v-model="company.name"></el-input>
          </el-form-item>

          <el-form-item label="客户对接人员">
            <el-select v-model="company.managerEmployeeUuid" placeholder="请选取客户对接人员">
              <el-option :label="employee.name" :value="employee.uuid" v-for="employee in employees"></el-option>
            </el-select>
            <span class="explain">客户后续服务对接联系人</span>
          </el-form-item>

          <el-form-item label="销售人员">
            <el-select v-model="company.contactEmployeeUuid" placeholder="请选取销售人员">
              <el-option :label="employee.name" :value="employee.uuid" v-for="employee in employees"></el-option>
            </el-select>
            <span class="explain">每个销售人员仅能看到当前及下级部门中相关客户</span>
          </el-form-item>

          <el-form-item label="客户地址">
            <map-container class="map" :lat.sync="company.lat" :lng.sync="company.lng" :province.sync="company.province"
                           :city.sync="company.city" :address.sync="company.address"></map-container>
            <p>地址信息:{{company.province}}/{{company.city}}/ {{company.address}}</p>

          </el-form-item>


          <el-form-item>
            <el-button type="primary" @click="handleCreateCompany">立即创建</el-button>
            <el-button @click="addCompanyVisible=false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </c-dialog>
  </div>

</template>

<script>
  import {provinceAndCityDataPlus, CodeToText} from 'element-china-area-data'
  import {companyUnion, companyList, companyCreate} from '@/api/marketing/combination/company'
  import {employeeCanChoice} from '@/api/account/employee'
  import CDialog from '@/components/Dialog'
  import MapContainer from '@/components/Map'
  import {mapGetters, mapActions} from "vuex";
  import {checkObj, checkPhone} from "@/util/checkLogin";

  export default {
    name: '',
    components: {
      CDialog,
      MapContainer
    },
    computed: {
      ...mapGetters(['employee'])
    },
    mounted: function () {
      this.loadCompany()
    },

    data: function () {
      return {
        options: provinceAndCityDataPlus,
        value: false,
        addCompanyVisible: false,
        pagination: {
          name: '',
          province: '',
          city: '',
          curPage: 1,
          limit: 10,
          total: 0,
          orderType: 0,
        },
        company: {
          name: '',
          contactEmployeeUuid: '',
          managerEmployeeUuid: '',
          province: '',
          city: '',
          address: '',
          lng: '',
          lat: '',
        },
        orderTypes: [
          {value: 0, label: '创建时间从大到小'},
          {value: 1, label: '创建时间从小到大'},
          {value: 3, label: '套餐数从大到小'},
          {value: 2, label: '套餐数从小到大'},
          {value: 5, label: '营收金额从小到大'},
          {value: 4, label: '营收金额从大到小'},
        ],
        makeTimeRange: [],
        companies: [],
        employees: [],
        pickerOptions: {

          shortcuts: [{
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0, 0, 0, 0)
              end.setHours(23, 59, 59, 0)
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              start.setHours(0, 0, 0, 0)
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              end.setHours(23, 59, 59, 0)

              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      }
    },
    methods: {
      ...mapActions(["setCompanyItem"]),
      handleAreaChange(value) {
        this.pagination.city = ''
        if (!value[0].length) {
          this.pagination.province = ''
          this.pagination.city = ''
        } else {
          this.pagination.province = CodeToText[value[0]]
          if (value[1].length) {
            let city = CodeToText[value[1]]
            if (city !== '全部') {
              this.pagination.city = city
            }
          }
        }
        this.loadCompany()
      },
      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        companyList(this.pagination).then(res => {
          this.companies = res.data || []
        })
      },
      handleGoDetail(company) {
        this.setCompanyItem(company)
        this.$router.push({name: 'marketing.combination.companies.detail', params: company})
      },
      handleFlushCompany() {
        this.loadCompany()
        this.$message.success('加载成功')
      },
      loadCompany() {
        companyUnion(this.pagination).then(res => {
          this.companies = res.data.list || []
          this.pagination.total = parseInt(res.data.total)
        })
      },
      handleCreateCompanyVisible() {
        if (this.employees.length === 0) {
          employeeCanChoice().then(res => {
            this.employees = res.data || []
          })
        }
        this.company = {
          name: '',
          contactEmployeeUuid: this.employee.uuid,
          managerEmployeeUuid: '',
          province: '',
          city: '',
          address: '',
          lng: '',
          lat: '',
        }
        this.addCompanyVisible = true
      },
      handleCreateCompany() {
        let check = checkObj(this.company, [
          {value: 'name', message: '请输入公司名称'},
          {value: 'contactEmployeeUuid', message: '请选取客户对接人'},
          {value: 'managerEmployeeUuid', message: '请选取销售人员'},
          {value: 'province', message: '请设置客户地理位置'},
          {value: 'city', message: '请设置客户地理位置'},
          {value: 'address', message: '请设置客户地理位置'},
          {value: 'lng', message: '请设置客户地理位置'},
          {value: 'lat', message: '请设置客户地理位置'},
        ])

        if (!check.status) {
          this.$message.warning(check.message)
          return
        }
        companyCreate(this.company).then(res => {
          this.companies.unshift({
            uuid: res.data,
            name: this.company.name,
            cups: 0,
            freeCups: 0,
            usableFreeCups: 0,
            combinations: 0,
            rent: 0,
            revenue: 0,
            province: this.company.province,
            city: this.company.city,
            address: this.company.address,
            lng: this.company.lng,
            lat: this.company.lat,
            sumCups: 0,
            employeeLimit: 0,
            hasOldCombination: 0,
          })
          this.$message.success('创建成功')
          this.addCompanyVisible = false

        })
      }
    },
  }
</script>

<style lang="scss">

  body.dark {
    .marketing-company {
      .el-input__inner {
        border-color: #858992;
      }
    }
  }

  .marketing-company {
    min-height: 608px;
    border-radius: $border-radius;
    @media (max-height: 768px) {
      min-height: 576px;
    }

    .marketing-company-filter {
      padding-top: $padding;
      margin-bottom: $margin;
      border-radius: $border-radius;
      background-color: $-theme-light-9;
    }
    &-empty{
      width: 100%;
      text-align: center;
      margin: $margin 0;
    }

    &-item {
      // border-radius: $border-radius;
      padding: $padding;
      margin-bottom: $margin;
      border-top: 1px solid;
      @include set_border_color($--border-color, $--border-color-dark);

      &-name {
        text-align: center;
      }

      &-content {
        .number {
          font-size: 16px;
          font-weight: 500;
          @include set_font_color($--font-01-color, $--font-color-dark);
          line-height: 2;
        }

        .el-col {
          text-align: center;
        }

        .switch {
          margin-top: 6px;
          margin-bottom: 6px;
        }

        .more {
          font-size: 24px;
          margin-top: 8px;
        }

        .more:hover {
          color: $_theme_color;
        }
      }
    }

    &-item:nth-last-child(2) {
      border-bottom: none;
    }

    &-item:hover {
      cursor: pointer;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, .1);
    }

    &-label {
      margin-bottom: $padding;
      @include set_font_color($--font-03-color, $--font-color-dark);

      .important {
        @include set_font_color($--font-color, $--font-color-dark);

      }
    }

    .create-company {
      padding-top: $padding;
      padding-right: $padding;

      .el-input__inner {
        max-width: 180px;
      }

      .map {
        height: 240px;
      }

      .explain {
        font-size: 12px;
        display: inline-block;
        margin-left: $padding-8;
        @include set_font_color($--font-03-color, $--font-color-dark);
      }
    }

    .warning {
      color: $btn_color;
    }

    .right {
      text-align: right;
    }


  }
</style>
